<!--
*  TTTech nerve-management-system
*  Copyright(c) 2021. TTTech Industrial Automation AG.
*
*  ALL RIGHTS RESERVED.
*
*  Usage of this software, including source code, netlists, documentation,
*  is subject to restrictions and conditions of the applicable license
*  agreement with TTTech Industrial Automation AG or its affiliates.
*
*  All trademarks used are the property of their respective owners.
*
*  TTTech Industrial Automation AG and its affiliates do not assume any liability
*  arising out of the application or use of any product described or shown
*  herein. TTTech Industrial Automation AG and its affiliates reserve the right to
*  make changes, at any time, in order to improve reliability, function or
*  design.
*
*  Contact Information:
*  support@tttech-industrial.com
*
*  TTTech Industrial Automation AG, Schoenbrunnerstrasse 7, 1040 Vienna, Austria
*
* -->

<template>
  <div class="mt-6 ml-6">
    <the-nerve-table
      id="iiotNodeUpdateLogList"
      :params="params"
      :columns="columns"
      :empty-state="$t('nodeUpdateLog.nodeUpdateLogTable.emptyList')"
      :is-action-menu-enabled="false"
      :is-row-clickable="canAccess('UI_NODE_UPDATE:LOG_VIEW')"
      custom-count-getter="getTotalLogs"
      :have-access="canAccess('UI_SUBNAV_NODE_UPDATE_LOG:VIEW')"
      :is-search-enabled="true"
      custom-list-getter="getNodeUpdateLog"
      store-module="node-update-log"
      class="pr-6"
      @params-changed="updateParams"
      @row-clicked="(params) => showDetails(params.item)"
    />
  </div>
</template>
<script>
import { TheNerveTable, ProgressLinearWithStatus } from 'nerve-ui-components';
import mqtt from '@/plugins/mqtt';

export default {
  components: {
    TheNerveTable,
  },
  props: {
    page: {
      type: Number,
      default: 1,
    },
    itemsPerPage: {
      type: Number,
      default: 10,
    },
    search: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      params: {},
    };
  },
  computed: {
    columns() {
      return [
        {
          text: this.$t('nodeUpdateLog.nodeUpdateLogTable.updateOperation'),
          value: 'operationName',
          sortable: false,
        },
        {
          text: this.$t('nodeUpdateLog.nodeUpdateLogTable.progress'),
          value: 'progress',
          width: '300px',
          component: {
            sfc: ProgressLinearWithStatus,
          },
          sortable: false,
        },
        {
          text: this.$t('nodeUpdateLog.nodeUpdateLogTable.started'),
          value: 'started',
          sortable: false,
        },
        {
          text: this.$t('nodeUpdateLog.nodeUpdateLogTable.finished'),
          value: 'finished',
          component: {
            sfc: () => import('@/components/shared/nerve-table/TimeOfOperationFinish.vue'),
          },
          sortable: false,
        },
        {
          text: this.canAccess('UI_NODE_UPDATE:LOG_DELETE') ? this.$t('defaultTable.action') : '',
          value: '',
          width: '0%',
          sortable: false,
          component: {
            sfc: () => import('@/components/nodes/node-update-log/NodeUpdateLogTableAction.vue'),
            props: {
              iconsAction: ['Delete', 'DisabledDelete'],
              fetchAction: () => this.filterBy,
            },
          },
        },
      ];
    },
  },
  async created() {
    this.params = {
      page: this.page || 1,
      itemsPerPage: this.itemsPerPage || 10,
      search: this.search || '',
    };
    await mqtt.subscribeTo('cloud');
    await this.$store.dispatch('node-update-log/setType', { type: 'update_node' });
  },
  beforeDestroy() {
    this.$mqtt.unsubscribeFrom('cloud');
  },
  methods: {
    async filterBy(action) {
      if (action === 'delete') {
        await this.$store.dispatch('node-update-log/fetch', this.params);
      } else {
        this.params.page = 1;
        await this.$store.dispatch('node-update-log/fetch', this.params);
      }
    },
    updateParams(parameters) {
      this.params = parameters;
      this.$router
        .push({
          name: 'Node Update Log',
          query: {
            page: parameters.page,
            itemsPerPage: parameters.itemsPerPage,
            search: parameters.search || undefined,
          },
        })
        .catch(() => {});
    },
    async showDetails(item) {
      await this.$store.dispatch('labels/save_query', this.params);
      this.$router.push({ name: 'Node Update Details', params: { id: item.id } });
    },
  },
};
</script>
